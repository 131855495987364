<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" @click="scrollToSection('hero')">
          <img src="@/assets/logo.png" alt="Logo">
        </a>

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="toggleNavbar">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-end">
          <a class="navbar-item" @click="scrollToSection('hero')">
            Home
          </a>
          <a class="navbar-item" @click="scrollToSection('services')">
            Services
          </a>
          <a class="navbar-item" @click="scrollToSection('about')">
            About Us
          </a>
          <a class="navbar-item" @click="scrollToSection('contact')">
            Contact Us
          </a>
          <div class="navbar-item">
            <div class="buttons">
              <a class="button is-light" @click="scrollToSection('contact')">
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Hero Section -->
    <section id="hero" class="hero is-fullheight-with-navbar is-primary">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-1">
            Empowering Your Vision through Exceptional Design, Software Development, and Product Management
          </h1>
          <!-- Add any other hero content here -->
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section id="services" class="section">
      <div class="container">
        <h2 class="title">Ignite Your Vision with Our Services</h2>
        <div class="columns">
          <!-- Design Service -->
          <div class="column is-4">
            <div class="card service-card">
              <div class="card-content">
                <p class="title has-text-centered">Design</p>
                <p class="subtitle has-text-centered">Crafting unforgettable user experiences, defining technical excellence, and shaping project scopes that transcend expectations.</p>
                <div class="has-text-centered">
                  <span class="icon is-large">
                    <i class="fas fa-paint-brush"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Development Service -->
          <div class="column is-4">
            <div class="card service-card">
              <div class="card-content">
                <p class="title has-text-centered">Development</p>
                <p class="subtitle has-text-centered">Embark on a journey where your project isn't just developed; it's brought to life with passion, precision, and a touch of magic.</p>
                <div class="has-text-centered">
                  <span class="icon is-large">
                    <i class="fas fa-code"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Consulting Service -->
          <div class="column is-4">
            <div class="card service-card">
              <div class="card-content">
                <p class="title has-text-centered">Consulting</p>
                <p class="subtitle has-text-centered">Navigate high-impact technical decisions effortlessly and conquer challenges with our expert consulting services. Your success, our commitment.</p>
                <div class="has-text-centered">
                  <span class="icon is-large">
                    <i class="fas fa-lightbulb"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



     <!-- About Us Section -->
    <section id="about" class="section">
      <div class="container">
        <h2 class="title">About Us</h2>
        <div class="content">
          <p>
            Explore the realm of possibilities with Catalyst Labs – a beacon of innovation in the realm of software consultancy. Committed to delivering groundbreaking solutions, we invite you to embark on a journey where technology meets creativity, and every challenge is an opportunity for excellence.
          </p>
          <p>
            At Catalyst Labs, our mission goes beyond coding; it's about crafting experiences that leave a lasting impression. We are dedicated to pushing boundaries, transforming ideas into reality, and shaping the digital future with a touch of uniqueness.
          </p>
          <p>
            What defines us is the relentless pursuit of innovation. Challenges are our playground, and each project is an opportunity to create something extraordinary. From concept to execution, we blend technical prowess with artistic flair to create solutions that not only meet but exceed expectations.
          </p>
          <p>
            Join us in the exciting journey where every line of code is a stroke of brilliance on the canvas of digital evolution. At Catalyst Labs, we don't just predict the future; we build it – with precision, passion, and a dash of magic.
          </p>
        </div>
      </div>
    </section>

    <!-- Contact Us Section -->
    <section id="contact" class="section">
      <div class="container">
        <h2 class="title">Contact Us</h2>
        <ContactForm />
        <div id="app">
    <router-view/>
  </div>
      </div>
    </section>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import ContactForm from "@/components/ContactForm.vue";
import "animate.css"; // Import Animate.css styles

export default {
  components: {
    Footer,
    ContactForm,
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    toggleNavbar() {
      const burger = document.querySelector('.burger');
      const menu = document.querySelector('#navbarBasicExample');

      burger.classList.toggle('is-active');
      menu.classList.toggle('is-active');
    },
  },
};
</script>

<style scoped>
/* Your Bulma styling goes here */

/* Example: Centered hero text */
.hero-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Example: Service cards styling */
.card {
  margin: 1rem;
  height: 100%;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Example: Styling for buttons in the navbar */
.navbar-item a.button {
  margin-left: 1rem;
}

/* Additional Bulma styling for a trendy look */
.navbar {
  border-bottom: 1px solid #fff; /* Add a border at the bottom of the navbar */
}

.navbar-item:hover {
  background-color: transparent; /* Remove background color on hover for a cleaner look */
}
.service-card {
  transition: background-color 0.3s ease, transform 0.3s ease;
  overflow: hidden;
}

.service-card:hover {
  background-color: #3273dc; /* Change this to the color of your hero section */
  transform: translateY(-10px);
}
/* Customize other styles based on your preferences */

</style>
