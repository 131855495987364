<template>
  <section class="section">
    <div class="container">
      <h2 class="title">Get in Touch</h2>
      <form @submit.prevent="submitForm">
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" type="text" v-model="formData.name" required />
          </div>
        </div>

        <div class="field">
          <label class="label">Email</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="email" v-model="formData.email" placeholder="jerome@catalystlabs.io" required />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
            <!-- You can add a success or error icon here based on validation -->
          </div>
        </div>

        <div class="field">
          <label class="label">Phone</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="tel" v-model="formData.phone" placeholder="8888888888" required />
            <span class="icon is-small is-left">
              <i class="fas fa-phone"></i>
            </span>
            <!-- You can add a success or error icon here based on validation -->
          </div>
        </div>

        <div class="field">
          <label class="label">Your Message</label>
          <div class="control">
            <textarea class="textarea" v-model="formData.message" required></textarea>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="button is-primary" type="submit">Send Message</button>
          </div>
        </div>
      </form>

      <!-- Display success message when submitted successfully -->
      <div v-if="isSubmitted" class="notification is-success">
        <button class="delete" @click="clearSuccessMessage"></button>
        <p>Message successfully sent! We'll be in touch soon.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { db, collection, addDoc } from '@/firebase';

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isSubmitted: false, // Track form submission status
    };
  },
  methods: {
    async submitForm() {
      // Validate email and phone number before submission
      if (!this.validateEmail(this.formData.email) || !this.validatePhone(this.formData.phone)) {
        // Display error message or handle validation as needed
        return;
      }

      try {
        await addDoc(collection(db, 'messages'), this.formData);

        // Log success message
        console.log('Form submitted:', this.formData);

        // Optionally, you can reset the form after submission
        this.formData = {
          name: '',
          email: '',
          phone: '',
          message: '',
        };

        // Set the form submission status to true
        this.isSubmitted = true;
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error as needed
      }
    },
    clearSuccessMessage() {
      // Clear the success message and allow the user to submit the form again
      this.isSubmitted = false;
    },
    validateEmail(email) {
      // You can implement a more sophisticated email validation if needed
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validatePhone(phone) {
      // You can implement a more sophisticated phone validation if needed
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(phone);
    },
  },
};
</script>

<style scoped>
/* Your component-specific styling goes here */
</style>
