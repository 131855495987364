<template>
  <section class="section">
    <div class="container">
      <h2 class="title">Apply for Careers</h2>
      <form @submit.prevent="submitApplication">
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" type="text" v-model="formData.name" required />
          </div>
        </div>

        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input class="input" type="email" v-model="formData.email" required />
          </div>
        </div>

        <div class="field">
          <label class="label">Cover Letter</label>
          <div class="control">
            <textarea class="textarea" v-model="formData.coverLetter" required></textarea>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="button is-primary" type="submit">Submit Application</button>
          </div>
        </div>
      </form>

      <!-- Display success message when submitted successfully -->
      <div v-if="isSubmitted" class="notification is-success">
        <button class="delete" @click="clearSuccessMessage"></button>
        <p>Application successfully submitted! We'll be in touch soon.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { db, collection, addDoc } from '@/firebase';

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        coverLetter: '',
      },
      isSubmitted: false, // Track form submission status
    };
  },
  methods: {
    async submitApplication() {
      try {
        // Add the applicant to the "careers" collection in Firebase
        await addDoc(collection(db, 'careers'), this.formData);

        // Log success message
        console.log('Application submitted:', this.formData);

        // Reset the form after submission
        this.formData = {
          name: '',
          email: '',
          coverLetter: '',
        };

        // Set the form submission status to true
        this.isSubmitted = true;
      } catch (error) {
        console.error('Error submitting application:', error);
        // Handle error as needed
      }
    },
    clearSuccessMessage() {
      // Clear the success message and allow the user to submit the form again
      this.isSubmitted = false;
    },
  },
};
</script>

<style scoped>
/* Your component-specific styling goes here */
</style>
