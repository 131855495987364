/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router';
import CareersPage from './components/CareersPage.vue';
import { createApp } from 'vue'; // Import createApp from Vue

const app = createApp(); // Create a Vue app instance

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/careers',
      name: 'Careers',
      component: CareersPage
    }
  ]
});

// Mount the router instance to the app
app.use(router);

// Export the router
export default router;
