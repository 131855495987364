<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <!-- Add a link to the Careers section -->
        <router-link to="/careers">Join Our Team</router-link>
      </p>
      <p>
        © 2024 catalyst Labs LLC. All rights reserved.
      </p>
    </div>
  </footer>
</template>
  
  <script>
  export default {
    // eslint-disable-next-line
    name: 'Footer', // Add the name property to resolve ESLint error
  };
  </script>
  
  <style scoped>
  /* Your component-specific styling goes here */
  </style>
  