import 'firebase/firestore'; // Import only the services you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC0JYeBw3lJr2Ghz7kHQosmRm9vIhjXlM4",
  authDomain: "catalystlabs-91c67.firebaseapp.com",
  projectId: "catalystlabs-91c67",
  storageBucket: "catalystlabs-91c67.appspot.com",
  messagingSenderId: "756808322538",
  appId: "1:756808322538:web:62ec45b182f78e592bf693",
  measurementId: "G-87XNG1FEFF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Export the specific functions and objects needed
export { db, collection, addDoc, app as firebaseApp };
